import React from 'react';
import './App.css';
import './kalender.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Kalender} from "./components/Kalender";
import {KalenderProvider} from "./context/KalenderContext";
import {LoginModal} from "./components/LoginModal";

// https://colorpalettes.net/color-palette-4284/

function App() {

    return (
        <KalenderProvider>
            <div className='app'>
                <Kalender/>

                <LoginModal/>
            </div>
        </KalenderProvider>
    );
}

export default App;
