export const defaultLuker2024 = [
    {
        id: 1,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 2,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 3,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 4,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 5,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 6,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 7,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 8,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 9,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 10,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 11,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 12,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 13,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 14,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 15,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 16,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 17,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 18,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 19,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 20,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },

    {
        id: 21,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 22,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 23,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
    {
        id: 24,
        image: 'https://img.freepik.com/free-vector/funny-santa-claus-celebrating-christmas_74855-962.jpg?w=1480&t=st=1667120254~exp=1667120854~hmac=3298b88eff77abb86cd16b12d711afd674d6c4533ef938ab5f69f40d44dc1fab',
        text: 'Dette er en test',
    },
];

export const mammaLuker2024 = [
    {
        id: 1,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_sig_klem.JPG?alt=media&token=00568b29-a88f-43ee-8f26-e3973cbfe12c',
        text: "Hei Mamma! Velkommen til Sigmund's julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!",
    },
    {
        id: 2,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_dykke.jpeg?alt=media&token=16867edb-39db-4d41-9ebe-6abe18b86025',
        text: 'Du er morsom, og jeg liker når du er med å finne på morsomme ting!',
    },
    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_spill_tresk.jpeg?alt=media&token=a003940b-689c-4e14-8096-51ee5e805979',
        text: 'Gleder meg til hyggelig familietid med deg!',
    },
    {
        id: 4,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_opppussing.jpeg?alt=media&token=b1352aa9-ab0e-4a35-8bc6-e5560e4e6c39',
        text: 'Takk for alt du gjør for meg! Du er flink til å gjøre det hyggelig rundt deg!',
    },
    {
        id: 5,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_morsom.JPG?alt=media&token=ef326533-3030-4135-a5b8-9b45cb56e694',
        text: 'Du kan lage morsomme ansikter!',
    },
    {
        id: 6,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_herb_hal_pa_bat.jpeg?alt=media&token=f1f45aa6-2657-4866-ad92-36c0b3775201',
        text: 'Gleder meg til flere familieturer med deg!',
    },
    {
        id: 7,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_kar_twister.JPG?alt=media&token=fe4b9c1e-8cda-41c0-9fa4-ff6684932f93',
        text: 'Vi syntes alle sammen der veldig gøy når du er med på leken!',
    },
    {
        id: 8,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_dart.jpg?alt=media&token=ebbd3877-1777-4c7e-a71a-5d829ada0009',
        text: 'Se så kjekk kjæresten din er!',
    },
    {
        id: 9,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_bat.JPG?alt=media&token=9ef557bc-e897-4d00-ae39-9a4be5e6872d',
        text: 'Jeg liker å godt å være på familieferie med deg!',
    },
    {
        id: 10,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ma_hytta.JPG?alt=media&token=79d18d8f-afc5-4dab-b59e-6273080477ad',
        text: 'Dere er fine sammen!',
    },
    {
        id: 11,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/kar_bat2.JPG?alt=media&token=3aa819d6-6fbe-454e-b98a-3952015181c8',
        text: 'Se se fin jenta di er!',
    },
    {
        id: 12,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_kajakk.jpeg?alt=media&token=44808ca8-990e-4906-9237-1832c99b6aab',
        text: 'Jeg syntes du er sporty, og er imponert over alle turene du drar på!',
    },
    {
        id: 13,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hallvard_og_sig_discgolf.jpeg?alt=media&token=1d474843-092f-4c50-8576-6e958c160e9a',
        text: 'Se på gutta dine!',
    },
    {
        id: 14,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_skjaer.JPG?alt=media&token=c980e618-0712-447c-ab3b-bb01429ac0df',
        text: 'I dag kan vi drømme om varmere dager - der vi kun trenger en varm fleece!',
    },
    {
        id: 15,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_sig_herb_hal_tur.jpeg?alt=media&token=53477383-50b4-4831-928c-ab323bb66db8',
        text: 'Det er fint å gå tur med deg!',
    },
    {
        id: 16,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_ski.JPG?alt=media&token=e549b67d-ddac-4ddf-b276-b19d9d0587aa',
        text: 'Her er et hyggelig minne fra en skitur der så ut som en is-dronning!',
    },
    {
        id: 17,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_polser_hytta.JPG?alt=media&token=73a56091-ee0d-4043-a084-e35ba39adc31',
        text: 'Se så hyggelig vi har det vi er alle sammen!',
    },
    {
        id: 18,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_sig_hal_ka_paske.jpeg?alt=media&token=06e79453-dcfb-43a0-9c05-21790e74c1fa',
        text: 'Se så fin gjengen din er!',
    },
    {
        id: 19,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ma_tresk.JPG?alt=media&token=c6c60733-f5db-4286-9bd7-e9a99b4d86d6',
        text: 'Jeg er glad i dere!',
    },
    {
        id: 20,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_middag_hytta.JPG?alt=media&token=28b3c242-3ea4-4ea7-a6d8-e4c78b92bb27',
        text: 'Vi er mange som er glad i deg!',
    },

    {
        id: 21,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_ma_pa_herb_konsert.jpeg?alt=media&token=e3da7793-d126-4c69-b285-a4ee48c2972b',
        text: 'Det er gøy å dra på konsert med deg!',
    },
    {
        id: 22,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_ski2.JPG?alt=media&token=2328cd33-bdc9-4777-9667-02ac0ac1aed3',
        text: 'Håper vi får noen fine turer sammen i romjula!',
    },
    {
        id: 23,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ma_ballong.jpeg?alt=media&token=53853ffc-5a85-4171-9588-e7dd02faefc0',
        text: 'Håper du er klar for masse fjas i jula',
    },
    {
        id: 24,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_konf.JPG?alt=media&token=e23d2afd-87bf-4c8f-a3ba-b44e2739a1d5',
        text: 'God jul! Vi er glad i deg!',
    },
];

export const pappaLuker2024 = [
    {
        id: 1,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ballong.jpeg?alt=media&token=99014c19-fad7-4e53-acef-7ceacf34db0b',
        text: "Hei Pappa! Velkommen til Sigmund's julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!",
    },
    {
        id: 2,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_dart.jpg?alt=media&token=ebbd3877-1777-4c7e-a71a-5d829ada0009',
        text: 'Jeg syntes det gøy å henge med deg! Det var veldig gøy når vi spilte dart på bursdagen din!',
    },
    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_bat.JPG?alt=media&token=9ef557bc-e897-4d00-ae39-9a4be5e6872d',
        text: 'Jeg syntes det er gøy å være på familiefereie med deg!',
    },
    {
        id: 4,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ma_hytta.JPG?alt=media&token=79d18d8f-afc5-4dab-b59e-6273080477ad',
        text: 'Se så fine dere er sammen!',
    },
    {
        id: 5,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_herb_sig_bat.JPG?alt=media&token=442dba09-bb3a-49f1-9298-539cf721bc8e',
        text: 'Jeg liker veldig godt stundene vi har sammen på hytta!',
    },
    {
        id: 6,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_dart.JPG?alt=media&token=09bfcfea-65f5-4455-8a8f-b331e466264e',
        text: 'Se på den flotte gjengen som var ute å feiret deg!',
    },
    {
        id: 7,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_sig_hal_ka_paske.jpeg?alt=media&token=06e79453-dcfb-43a0-9c05-21790e74c1fa',
        text: 'Det er gøy at du ikke gir slipp på tradisjoner og leker!',
    },
    {
        id: 8,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_male.jpeg?alt=media&token=b3cf95a7-6250-4d19-b85c-16fa43222b8a',
        text: 'Tusen takk for alt du gjør for meg. Det setter jeg utrolig stor pris på!',
    },
    {
        id: 9,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_twister.JPG?alt=media&token=32258a6b-fe4a-499f-8ad2-b8f1bac27e8a',
        text: 'Gleder meg til å slå deg i twister igjen!',
    },
    {
        id: 10,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ma_tresk.JPG?alt=media&token=c6c60733-f5db-4286-9bd7-e9a99b4d86d6',
        text: 'Vi er glad i deg!',
    },
    {
        id: 11,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_herb_sig_bat.jpeg?alt=media&token=c7a650d8-1617-44b3-ad02-eec5289c49f8',
        text: 'Det er fint a dra å se på havet sammen med deg!',
    },
    {
        id: 12,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hallvard_og_sig_discgolf.jpeg?alt=media&token=1d474843-092f-4c50-8576-6e958c160e9a',
        text: 'Se på gutta dine!',
    },
    {
        id: 13,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_polser_hytta.JPG?alt=media&token=73a56091-ee0d-4043-a084-e35ba39adc31',
        text: 'Takk for alle opplevelsen du tar oss med på!',
    },
    {
        id: 14,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_morsom.JPG?alt=media&token=04ba7fb9-c1a9-4ae9-9ec5-6c9693dbb339',
        text: 'Det er artig når du forteller morsomme vitser og historier!',
    },
    {
        id: 15,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/kar_bat2.JPG?alt=media&token=3aa819d6-6fbe-454e-b98a-3952015181c8',
        text: 'Se så fin lille jenta di er!',
    },
    {
        id: 16,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_skjaer.JPG?alt=media&token=c980e618-0712-447c-ab3b-bb01429ac0df',
        text: 'Jeg gleder meg til flere skjærturer med deg!',
    },
    {
        id: 17,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_sig_herb_kar_middag.jpeg?alt=media&token=8d9002df-f7bc-4678-aca5-51f847d5d9de',
        text: 'I dag syntes jeg vi skal drømme oss bort til varmere dager!',
    },
    {
        id: 18,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_ma_pa_herb_konsert.jpeg?alt=media&token=e3da7793-d126-4c69-b285-a4ee48c2972b',
        text: 'Det er gøy å dra på konsert med deg!',
    },
    {
        id: 19,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ha_bat.jpg?alt=media&token=3593541f-29f6-4221-a4ff-d1fc92c97987',
        text: 'Gutta på tur!',
    },
    {
        id: 20,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_sommerkino.JPG?alt=media&token=1ab558f4-350b-41cf-970c-bb7ac870870e',
        text: 'Se den flotte gjengen som vil se på sommerkino med deg hver sommer!',
    },

    {
        id: 21,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_spill_tresk.jpeg?alt=media&token=a003940b-689c-4e14-8096-51ee5e805979',
        text: 'Du er bra som alltid er med på leken!',
    },
    {
        id: 22,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ski.JPG?alt=media&token=7289d4ec-985d-4f87-81e7-0b0c077fac4d',
        text: 'Du er sporty',
    },
    {
        id: 23,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ma_herb_frokost.jpeg?alt=media&token=b133b281-a080-4077-b58e-05b2c04edcd9',
        text: 'Takk for alle de fine frokostene du lager!',
    },
    {
        id: 24,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_konf.JPG?alt=media&token=e23d2afd-87bf-4c8f-a3ba-b44e2739a1d5',
        text: 'Vi er glad i deg! God jul!',
    },
];

export const hallvardLuker2024 = [
    {
        id: 1,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hallvard_og_sig_discgolf.jpeg?alt=media&token=1d474843-092f-4c50-8576-6e958c160e9a',
        text: "Hei Hallvard! Velkommen til Sigmund's julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!",
    },
    {
        id: 2,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_klatring.jpeg?alt=media&token=aae42e2f-4151-4a52-9d33-0036cc307486',
        text: 'Du er skikkelig kjekkas!',
    },
    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_middag_hytta.JPG?alt=media&token=28b3c242-3ea4-4ea7-a6d8-e4c78b92bb27',
        text: 'Det er hyggelig å være på hytta med deg!',
    },
    {
        id: 4,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_sig_bat.jpeg?alt=media&token=976c3c9a-c61f-4065-8dd6-0d1d0a913947',
        text: 'Jeg digger å være på båttur med deg!',
    },
    {
        id: 5,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_skjaer.JPG?alt=media&token=c980e618-0712-447c-ab3b-bb01429ac0df',
        text: 'Hvis det er litt kaldt i dag, kan vi drømme oss bort til varmere dager!',
    },
    {
        id: 6,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_sig_herb_kafe.jpeg?alt=media&token=aa445a94-4476-4e57-a524-3d1bb2accedb',
        text: 'Jeg syntes der er hyggelig å kunne henge med deg!',
    },
    {
        id: 7,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_bat.JPG?alt=media&token=9ef557bc-e897-4d00-ae39-9a4be5e6872d',
        text: 'Jeg liker godt å være på familieferie med deg!',
    },
    {
        id: 8,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_sig_hal_ka_paske.jpeg?alt=media&token=06e79453-dcfb-43a0-9c05-21790e74c1fa',
        text: 'Her er et minne fra påsken!',
    },
    {
        id: 9,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_sig_krabbe.jpeg?alt=media&token=055fe804-a656-4f60-a28c-b0e43f95dc83',
        text: 'Du er råtass på fiske. Takk for at du tar med meg!',
    },
    {
        id: 10,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_dart.JPG?alt=media&token=09bfcfea-65f5-4455-8a8f-b331e466264e',
        text: 'Se så fin gjeng vi er!',
    },
    {
        id: 11,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_klatring.JPG?alt=media&token=b8bd6e9b-d5b2-4dd8-8a8a-0aeb4d85b7d5',
        text: 'Du er sporty og tøff! Jeg syntes det er kult når du utfordrer deg selv!',
    },
    {
        id: 12,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_sint.JPG?alt=media&token=b55d4944-bd36-455b-a012-d6fd5f13524f',
        text: 'Husker ikke helt hvorfor du var sint her, men det var litt gøy!',
    },
    {
        id: 13,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ma_hytta.JPG?alt=media&token=79d18d8f-afc5-4dab-b59e-6273080477ad',
        text: 'Se så fine mamma og pappa er sammen!',
    },
    {
        id: 14,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_dart.jpeg?alt=media&token=1d53170c-1529-4091-a3eb-55751fdd0566',
        text: 'Det er alltid gøy når du er med på leken!',
    },
    {
        id: 15,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_tur.jpeg?alt=media&token=127bbc5a-93ad-44ab-a10c-9a363bb520b2',
        text: 'Jeg liker godt at du alltid vil være med på det som skjer!',
    },
    {
        id: 16,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hallvard_discgolf.jpeg?alt=media&token=e096b527-3dc3-4def-ac22-5a58bab49f91',
        text: 'Gleder meg til neste discgolf runde!',
    },
    {
        id: 17,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_spill_tresk.jpeg?alt=media&token=a003940b-689c-4e14-8096-51ee5e805979',
        text: 'Gleder meg til å henge med deg i jula!',
    },
    {
        id: 18,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_vannmelon.JPG?alt=media&token=46b1b63e-4f65-4191-bc6a-c770d49c57a6',
        text: 'Du er en skikkelig tullekopp!',
    },
    {
        id: 19,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_ma_pa_herb_konsert.jpeg?alt=media&token=e3da7793-d126-4c69-b285-a4ee48c2972b',
        text: 'Her er din number one fan club! Vi digger når du spiller!',
    },
    {
        id: 20,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_minigolf.jpeg?alt=media&token=d4e950eb-f450-4de5-acf3-a10832aa598d',
        text: 'Det var gøy at du tok oss med på minigolf på burdagen din!',
    },

    {
        id: 21,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_vannski.JPG?alt=media&token=99f02f16-c6a8-4771-9702-52e02b848918',
        text: 'Du er utrolig god til å sørge for at de rundt deg har det bra!',
    },
    {
        id: 22,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/hal_dykke.jpeg?alt=media&token=68692a08-244d-4840-90ac-a0b46881b2b8',
        text: 'Du er en fet fyr!',
    },
    {
        id: 23,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ha_flagg.jpg?alt=media&token=5971288e-aac5-4e33-a715-308f3ec27fed',
        text: 'Du er kjekkas som passer på at ting alltid er i orden!',
    },
    {
        id: 24,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_konf.JPG?alt=media&token=e23d2afd-87bf-4c8f-a3ba-b44e2739a1d5',
        text: 'Jeg er glad i deg! God jul!',
    },
];

export const karineLuker2024 = [
    {
        id: 1,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/kar_bat2.JPG?alt=media&token=3aa819d6-6fbe-454e-b98a-3952015181c8',
        text: "Hei Karine! Velkommen til Sigmund's julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!",
    },
    {
        id: 2,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/kar_twister.JPG?alt=media&token=bc660f0b-5070-465f-9e80-4d77be9ff752',
        text: 'Nå er det bare å begynne å glede seg til familiefjas. Vi gleder oss til du kommer hjem!',
    },
    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_sig_hal_ka_paske.jpeg?alt=media&token=06e79453-dcfb-43a0-9c05-21790e74c1fa',
        text: 'Når du spiser godteri blir du helt tullete!',
    },
    {
        id: 4,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/kar_klatre.jpeg?alt=media&token=c348f639-97fb-4900-86e7-57903379a141',
        text: 'Du er sporty! Jeg liker å klatre med deg!',
    },
    {
        id: 5,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_topptur.jpg?alt=media&token=929b7c28-1fe1-4076-87f5-c36fa7687715',
        text: 'Har vinteren kommet til Trondheim enda? Håper du får mange fine toppturer i vinter!',
    },
    {
        id: 6,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/pa_ma_hytta.JPG?alt=media&token=79d18d8f-afc5-4dab-b59e-6273080477ad',
        text: 'Se så fine mamma og pappa er sammen!',
    },
    {
        id: 7,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/kar_bat.jpg?alt=media&token=deaf17f6-f617-47b6-82bc-cea9d74c5349',
        text: 'Her er et fint minne fra en båttur i sommer!',
    },
    {
        id: 8,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_vannski.jpeg?alt=media&token=7147d816-e6ca-45c9-90f3-446f855b9876',
        text: 'Takk for at du drar meg på vannski selv om du syntes jeg er litt for tung!',
    },
    {
        id: 9,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_spill_tresk.jpeg?alt=media&token=a003940b-689c-4e14-8096-51ee5e805979',
        text: 'Vet ikke helt hva du så her, men vi andre hadde det fint!',
    },
    {
        id: 10,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/kar_klatre2.JPG?alt=media&token=900fd094-2fb0-4489-b6a4-3a36f6f5dbd3',
        text: 'Du lager veldig morsomme ansikter når du klatrer!',
    },
    {
        id: 11,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_ma_pa_herb_konsert.jpeg?alt=media&token=e3da7793-d126-4c69-b285-a4ee48c2972b',
        text: 'Det er gøy å dra på konsert med deg!',
    },
    {
        id: 12,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_bat.JPG?alt=media&token=9ef557bc-e897-4d00-ae39-9a4be5e6872d',
        text: 'Jeg liker godt å være på familieferie med deg!',
    },
    {
        id: 13,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/kar_sig_herb_middag.jpeg?alt=media&token=bbf7a2a2-95b7-4479-8d6a-bc75481b7842',
        text: 'Jeg liker når du tuller og tøyser!',
    },
    {
        id: 14,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_bat3.JPG?alt=media&token=09fbad5c-fbb4-484c-a42b-f63960626603',
        text: 'Alt: Karine gir tommel opp! <- Tar du den vitsen?',
    },
    {
        id: 15,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_ballong.jpeg?alt=media&token=f016b7cf-47e0-4e63-8486-650c4d3f8e70',
        text: 'Her er det enda mer tull og tøys!',
    },
    {
        id: 16,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_middag_hytta.JPG?alt=media&token=28b3c242-3ea4-4ea7-a6d8-e4c78b92bb27',
        text: 'Gleder meg til mange hyggelige middager med deg i jula!',
    },
    {
        id: 17,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_julaften.JPG?alt=media&token=7b44af2d-2be5-4766-a266-7df4fde86496',
        text: 'Jeg syntes det er gøy når du fjaser!',
    },
    {
        id: 18,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_sv%C3%B8mming.jpg?alt=media&token=b65e1eaa-4630-4f87-93f7-c33c33f333d9',
        text: 'Det er gøy å bade med deg!',
    },
    {
        id: 19,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_polser_hytta.JPG?alt=media&token=73a56091-ee0d-4043-a084-e35ba39adc31',
        text: 'Her er et hyggelig minne fra i sommmer når vi var å hentet deg etter speiderleiren du var på!',
    },
    {
        id: 20,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_sig_kajakk.jpg?alt=media&token=f1a26e44-a5b1-4950-88ec-96eb0828ea00',
        text: 'Husker du når vi prøvede å lære oss eskimorulle sammen?',
    },

    {
        id: 21,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/kar_twister2.JPG?alt=media&token=f875f4e2-9410-43e5-96b6-be2bf2192132',
        text: 'Hvor mange runder med twister tror du vi klarer i jula?',
    },
    {
        id: 22,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_kaltring.jpg?alt=media&token=82cd2a72-6eeb-455a-a458-7ffb06a1581a',
        text: 'Her er et hyggelig minne fra en de første gangene vi klatret sammen!',
    },
    {
        id: 23,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/ka_juletre.jpg?alt=media&token=13b99aa2-c4a5-4474-8dd1-584e40025f22',
        text: 'Har vi fått opp juletre sammen i år?',
    },
    {
        id: 24,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/felles_konf.JPG?alt=media&token=e23d2afd-87bf-4c8f-a3ba-b44e2739a1d5',
        text: 'God jul! Jeg er glad i deg!',
    },
];

export const herborgLuker2024 = [
    {
        id: 1,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_1.JPG?alt=media&token=cf10a9e6-fe7f-481b-84e5-1b50eae88fd3',
        text: 'Hei Herborg! Velkommen til Sigmund\'s julekalender. Her vil du finne en ny luke hver dag frem til julaften. Håper du liker det!',
    },
    {
        id: 2,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_2.jpeg?alt=media&token=778561ac-54e3-469d-a823-e5e946f706e4',
        text: 'Jeg liker å bo med deg, og er veldig glad for at vi har fått en så fin leilighet sammen!',
    },
    {
        id: 3,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_3.jpeg?alt=media&token=f64ed19d-d22b-4e20-b2e7-5e53ad151adf',
        text: 'Gleder meg til romjula i Isfjorden med deg! Tror det det blir noe skitur?',
    },
    {
        id: 4,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_4.jpeg?alt=media&token=56c583cd-dd35-40c9-91a0-065937755a9a',
        text: 'Jeg liker godt å henge med familien din og syntes det er hyggelig at jeg får være en del av gjengen!',
    },
    {
        id: 5,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_5.JPG?alt=media&token=45de1fe1-c4d4-4125-bed0-6559d0679125',
        text: 'Du er sporty! Jeg er imponert over alt du tørr å prøve!',
    },
    {
        id: 6,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_6.jpeg?alt=media&token=e441ed25-07b2-421f-84e5-fb6ad462b83e',
        text: 'Takk for at du har fått meg til å like løping! Gleder meg til å løpe sammen med deg igjen <3',
    },
    {
        id: 7,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_7.jpeg?alt=media&token=0c65b347-7824-42b5-829b-54dc9f24d76e',
        text: 'Du er søt! Jeg liker alle de gode (og mindre gode) ideene dine!',
    },
    {
        id: 8,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_8.jpeg?alt=media&token=3108fc7a-bc02-4f39-bf12-6160033271b1',
        text: 'Takk for at du vil henge med meg og vennene mine!',
    },
    {
        id: 9,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_9.jpg?alt=media&token=1ee9e3ba-2111-4358-ad98-dd9012876ae2',
        text: 'Er det litt kladt i dag? Da kan vi drømme oss bort til varmere dager!',
    },
    {
        id: 10,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_10.jpeg?alt=media&token=d02b2054-3a26-4fac-8d50-dd9fa08f2b55',
        text: 'Du kan mange kule triks!',
    },
    {
        id: 11,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_11.jpeg?alt=media&token=d819a8cb-ec8d-46e2-8c16-09f5c880743c',
        text: 'Jeg er imponert over alt du får til på jobben. Jeg tror der er flinkere enn du skjønner selv!',
    },
    {
        id: 12,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_12.jpeg?alt=media&token=af5bc656-2f1f-4c41-ac26-9dc6cd854364',
        text: 'Denne turen imponerte meg skikkelig! Du er rå!',
    },
    {
        id: 13,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_13.jpeg?alt=media&token=5d70130a-a085-4e24-abd6-e6ad231d6dec',
        text: 'Du er vakker!',
    },
    {
        id: 14,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_14.jpeg?alt=media&token=20ed3d7b-055b-4167-a4e1-41dc486364ce',
        text: 'Takk for at du alltid er med på å gjøre kule ting!',
    },
    {
        id: 15,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_15.jpeg?alt=media&token=07b749bb-85a7-4a45-b5e7-dca6ec7d7da1',
        text: 'Jeg syntes det er skikkelig koslig at jeg får være med på familietur til Skeikampen!',
    },
    {
        id: 16,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_16.jpeg?alt=media&token=fa8bb393-3fdc-40f7-bf0a-77131f71f93c',
        text: 'Du er en mester på å lage god mat! Både inn og ute!',
    },
    {
        id: 17,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_17.jpeg?alt=media&token=9b1544b6-20a8-4797-9de3-d4adfa403077',
        text: 'Jeg liker godt å være med deg sammen med familien min. Du er en ordentlig bra dame!',
    },
    {
        id: 18,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_18.jpeg?alt=media&token=9f5bb0b3-28f8-462e-8fe4-250308662e6a',
        text: 'Gleder meg til mange turer med deg fremover!',
    },
    {
        id: 19,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_19.jpeg?alt=media&token=9b1ea856-7305-4c5c-813d-a5786ed1451d',
        text: 'Du er en tøffing!',
    },
    {
        id: 20,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_20.jpeg?alt=media&token=c1640878-682d-45a3-b90d-85bd1e1c7eb8',
        text: 'Dette syntes jeg var en skikkelig fin tur! Håper vi får en sånn tur til sommeren også!',
    },

    {
        id: 21,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_21.jpeg?alt=media&token=ef972931-1eea-49dd-8cee-fc1e6fe480f1',
        text: 'Jeg liker å vise deg alt som er viktig for meg, og syntes det er gøy at du vil være med!',
    },
    {
        id: 22,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_22.jpeg?alt=media&token=9c978793-096b-47a5-af94-7c8006bed2c1',
        text: 'Se hun er sporty <3',
    },
    {
        id: 23,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_23.jpg?alt=media&token=e5f7c57b-9648-4774-a18e-d1c745656744',
        text: 'Jeg gleder meg til å henge masse med deg fremover. Du er fantastisk!',
    },
    {
        id: 24,
        image: 'https://firebasestorage.googleapis.com/v0/b/julekalender-efc91.appspot.com/o/herb_24.JPG?alt=media&token=1bfbdb1b-d66b-4b2e-970e-87d032b5c1fe',
        text: 'God jul! Jeg er glad i deg!',
    },
];
