import Luke, {LukeType} from "./Luke";
import React from "react";
import {useKalender} from "../context/KalenderContext";
import {Header} from "./Header";
import {Bakgrunn} from "./Bakgrunn";
import {KalenderVelger} from "./KalenderVelger";


export const Kalender = () => {

    const {
        luker,
        lukerÅpnet,
    } = useKalender();

    return (
        <>
            <Bakgrunn/>
            <Header/>
            <div className='kalender'>
                {luker.map((luke: LukeType, index: number) => (
                    <Luke
                        key={luke.id}
                        lukeNummer={luke.id}
                        image={luke.image}
                        text={luke.text}
                        open={lukerÅpnet[index]}
                    />
                ))}
            </div>
            <KalenderVelger/>
        </>
    );
}