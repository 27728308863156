import sigmund from "../images/sigmund.png";
import React from "react";
import '../style/header.css';

export const Header = () =>
    <div className='header'>
        <h1>Sigmund's julekalender</h1>
        <img src={sigmund} alt='sigmund' className='pc'/>
        <div className='sigmund_row'>
            <img src={sigmund} alt='sigmund' className='mobile'/>
            <img src={sigmund} alt='sigmund' className='mobile'/>
            <img src={sigmund} alt='sigmund' className='mobile'/>
        </div>
    </div>
