import React from "react";
import {KalenderNavn, useKalender} from "../context/KalenderContext";
import '../style/kalenderVelger.css';
import {enumTilNavn, navnTilEnum} from "../utils/utils";

export const KalenderVelger = () => {

    const {
        setKalenderNavn,
        kalenderNavn,
        isChristmasMode,
        setKalenderÅr,
        kalenderÅr
    } = useKalender();

    return (
        <div className={'kalender-velger-container'}>
            <div className={"select-container"}>
                <label>Kalender:</label>
                <select onChange={(event) => setKalenderNavn(navnTilEnum(event.target.value))} value={enumTilNavn(kalenderNavn)}>
                    <option value='Mamma'>Mamma</option>
                    <option value='Pappa'>Pappa</option>
                    <option value='Hallvard'>Hallvard</option>
                    <option value='Karine'>Karine</option>
                    {navnTilEnum(localStorage.getItem('navn')) === KalenderNavn.Herborg && (
                        <option value='Herborg'>Herborg</option>
                    )}
                </select>
            </div>
            {
                isChristmasMode && (
                    <div className={"select-container"}>
                        <label>År:</label>
                        <select onChange={(event) => {
                            setKalenderÅr(Number(event.target.value))
                        }} value={kalenderÅr}>
                            <option value={2022}>2022</option>
                            <option value={2024}>2024</option>
                        </select>
                    </div>
                )
            }
        </div>
    )
}