import {
    hallvardLuker2022,
    herborgLuker2022,
    karineLuker2022,
    mammaLuker2022,
    pappaLuker2022,
} from '../luker/luker2022';
import {
    defaultLuker2024,
    hallvardLuker2024,
    herborgLuker2024,
    karineLuker2024,
    mammaLuker2024,
    pappaLuker2024,
} from '../luker/luker2024';
import {LukeType} from "../components/Luke";
import {KalenderNavn} from "../context/KalenderContext";

export const navnTilEnum = (navn: string | null): KalenderNavn => {
    switch (navn?.toLocaleLowerCase()) {
        case 'mamma':
            return KalenderNavn.Mamma;
        case 'pappa':
            return KalenderNavn.Pappa;
        case 'hallvard':
            return KalenderNavn.Hallvard;
        case 'karine':
            return KalenderNavn.Karine;
        case 'herborg':
            return KalenderNavn.Herborg;
    }
    return KalenderNavn.Ingen;
}

export const enumTilNavn = (navn: KalenderNavn): string => {
    switch (navn) {
        case KalenderNavn.Mamma:
            return 'Mamma';
        case KalenderNavn.Pappa:
            return 'Pappa';
        case KalenderNavn.Hallvard:
            return 'Hallvard';
        case KalenderNavn.Karine:
            return 'Karine';
        case KalenderNavn.Herborg:
            return 'Herborg';
        case KalenderNavn.Ingen:
            return 'Ingen';
    }
}

export const finnLuker = (kalenderNavn: KalenderNavn, kalenderÅr: number): LukeType[] => {
    if (kalenderNavn === KalenderNavn.Mamma) {
        if (kalenderÅr === 2022) {
            return mammaLuker2022;
        }
        return mammaLuker2024;
    } else if (kalenderNavn === KalenderNavn.Pappa) {
        if (kalenderÅr === 2022) {
            return pappaLuker2022;
        }
        return pappaLuker2024;
    } else if (kalenderNavn === KalenderNavn.Hallvard) {
        if (kalenderÅr === 2022) {
            return hallvardLuker2022;
        }
        return hallvardLuker2024;
    } else if (kalenderNavn === KalenderNavn.Karine) {
        if (kalenderÅr === 2022) {
            return karineLuker2022;
        }
        return karineLuker2024;
    } else if (kalenderNavn === KalenderNavn.Herborg) {
        if (kalenderÅr === 2022) {
            return herborgLuker2022;
        }
        return herborgLuker2024;
    }
    return defaultLuker2024
}

export const finnLoginFeilmelding = (navn: String) => {
    const mammaNavn = ['kristine', 'kristine berbom'];
    const pappaNavn = ['thomas', 'thomas berbom'];
    if (mammaNavn.includes(navn.toLocaleLowerCase())) {
        return ('Du er ikke Kristine, du er Mamma!');
    }
    if (pappaNavn.includes(navn.toLocaleLowerCase())) {
        return ('Du er ikke Thomas, du er Pappa!');
    }
    return 'Kjenner ikke navnet, prøv igjen';
};
