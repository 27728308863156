import {doc, getDoc, setDoc} from "firebase/firestore";
import {db} from "../firebase";
import {alleLukerLukket, alleLukerÅpnet} from "./consts";
import {KalenderNavn} from "../context/KalenderContext";
import {enumTilNavn} from "./utils";

export const hentÅpneLuker = async (navn: KalenderNavn, kalenderÅr: number): Promise<boolean[]> => {
    if (kalenderÅr === new Date().getFullYear() && navn) {
        return getDoc(doc(db, 'julekalender', enumTilNavn(navn))).then((document) =>
            document.exists() ? document.data().lukerÅpnet : alleLukerLukket
        ).catch((error) => {
            console.error('Error getting document:', error);
            return alleLukerLukket;
        });
    }
    return alleLukerÅpnet;
};

export const oppdaterLuker = (kalenderNavn: KalenderNavn, nyeLukerÅpnet: boolean[]) => {
    setDoc(doc(db, 'julekalender', enumTilNavn(kalenderNavn)), {
        lukerÅpnet: nyeLukerÅpnet,
    }).catch((error) => {
        console.error('Error updating document: ', error);
    })
}
