import Snowfall from "react-snowfall";
import santa from "../images/santa.png";
import raindeer from "../images/raindeer2.png";
import React from "react";
import '../style/bakgrunn.css';

export const Bakgrunn = () =>
    <>
        <Snowfall
            style={{
                zIndex: '-2',
                position: 'fixed',
                width: '100vw',
                height: '100vh',
            }}
        />
        <div className='santa-container pc'>
            <img src={santa} alt='Santa' className='santa pc'/>
        </div>
        <div className='raindeer-container pc'>
            <img src={raindeer} alt='raindeer' className='raindeer pc'/>
        </div>
    </>