import constate from "constate";
import {useEffect, useState} from "react";
import {finnLuker, navnTilEnum,} from "../utils/utils";
import {defaultLuker2022} from "../luker/luker2022";
import {alleLukerLukket} from "../utils/consts";
import {hentÅpneLuker, oppdaterLuker} from "../utils/api";
import {LukeType} from "../components/Luke";

export enum KalenderNavn {
    Mamma = 'Mamma',
    Pappa = 'Pappa',
    Hallvard = 'Hallvard',
    Karine = 'Karine',
    Herborg = 'Herborg',
    Ingen = 'Ingen',
}

export const [KalenderProvider, useKalender] = constate(
    () => {

        const isDev = window.location.hostname === 'localhost';
        const isChristmasMode = (isDev || new Date() >= new Date("12.01.2024") || true)

        const [kalenderNavn, setKalenderNavn] = useState<KalenderNavn>(navnTilEnum(localStorage.getItem('navn')));
        const [showLoginModal, setShowLoginModal] = useState(true);
        const [feilmelding, setFeilmelding] = useState('');
        const [luker, setLuker] = useState<LukeType[]>(defaultLuker2022);
        const [kalenderÅr, setKalenderÅr] = useState(isChristmasMode ? 2024 : 2022);
        const [lukerÅpnet, setLukerÅpnet] = useState(alleLukerLukket);


        useEffect(() => {
            const storageNavn = navnTilEnum(localStorage.getItem('navn'));
            if (storageNavn !== null && storageNavn !== KalenderNavn.Ingen) {
                byttKalender(storageNavn, kalenderÅr);
                setKalenderNavn(storageNavn);
                setShowLoginModal(false);
            }
        }, []);

        useEffect(() => {
            hentÅpneLuker(kalenderNavn, kalenderÅr).then((åpneLuker) => {
                setLukerÅpnet(åpneLuker);
                byttKalender(kalenderNavn, kalenderÅr);
            })
        }, [kalenderNavn, kalenderÅr]);


        const byttKalender = (navn: KalenderNavn, år: number) => {
            setLuker(finnLuker(navn, år));
            hentÅpneLuker(navn, kalenderÅr).then(setLukerÅpnet);
        };

        const handleLukeÅpnet = async (lukeNummer: number) => {
            if (kalenderÅr === new Date().getFullYear()) {
                const nyeLukerÅpnet = [...lukerÅpnet];
                nyeLukerÅpnet[lukeNummer - 1] = !nyeLukerÅpnet[lukeNummer - 1];
                setLukerÅpnet(nyeLukerÅpnet);
                oppdaterLuker(kalenderNavn, nyeLukerÅpnet);
            }
        }

        return {
            kalenderNavn,
            setKalenderNavn,
            show: showLoginModal,
            setShow: setShowLoginModal,
            feilmelding,
            setFeilmelding,
            kalenderÅr,
            setKalenderÅr,
            luker,
            lukerÅpnet,
            isChristmasMode,
            byttKalender,
            handleLukeÅpnet
        };
    }
);
