import React, {useEffect, useState} from 'react';
import '../style/luke.css';
import Modal from 'react-bootstrap/Modal';
import grinch from '../images/grinch.png';
import {useKalender} from "../context/KalenderContext";
import {navnTilEnum} from "../utils/utils";

interface Props {
    lukeNummer: number;
    image: string;
    text: string;
    open: boolean;
}

export interface LukeType {
    id: number;
    image: string;
    text: string;
}

function Luke({
                  lukeNummer,
                  image,
                  text,
                  open,
              }: Props) {
    const {kalenderNavn, handleLukeÅpnet} = useKalender();

    const [showLukeInnhold, setShowLukeInnhold] = useState(false);
    const [showGrinche, setShowGrinche] = useState(false);
    const handleClose = () => setShowLukeInnhold(false);
    const handleShow = () => setShowLukeInnhold(true);

    const today = new Date();
    const day = today.getDate();

    useEffect(() => {
        if (showGrinche) {
            setTimeout(() => {
                setShowGrinche(false);
            }, 2000);
        }
    }, [showGrinche]);

    const handleClick = () => {
        if (
            day >= lukeNummer &&
            kalenderNavn === navnTilEnum(localStorage.getItem('navn'))
        ) {
            handleLukeÅpnet(lukeNummer);
        } else {
            setShowGrinche(true);
        }
    };


    return (
        <div className='luke' onClick={handleClick}>
            {open ? (
                <img src={image} alt='luke bilde' onClick={handleShow}/>
            ) : (
                <>
                    {showGrinche ? (
                        <img src={grinch} alt={'grinch'} className='grinch'/>
                    ) : (
                        <p>{lukeNummer}</p>
                    )}
                </>
            )}
            <Modal
                show={showLukeInnhold}
                onHide={handleClose}
                size='lg'
                className='luke_modal'
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Velkommen til luke nr. {lukeNummer}!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-body-container'>
                        <img
                            src={image}
                            alt='luke bilde'
                            onClick={handleShow}
                        />
                        <p>{text}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default Luke;
