import {Button, Form, InputGroup, Modal} from "react-bootstrap";
import React from "react";
import {KalenderNavn, useKalender} from "../context/KalenderContext";
import {enumTilNavn, finnLoginFeilmelding, navnTilEnum} from "../utils/utils";
import '../style/loginModal.css';


export const LoginModal = () => {
    const {
        setKalenderNavn,
        kalenderNavn,
        show,
        setShow,
        feilmelding,
        setFeilmelding,
        kalenderÅr,
        byttKalender
    } = useKalender();


    const handleLogin = () => {
        if (kalenderNavn !== KalenderNavn.Ingen) {
            setShow(false);
            byttKalender(kalenderNavn, kalenderÅr);
            localStorage.setItem('navn', enumTilNavn(kalenderNavn));
        } else {
            setFeilmelding(finnLoginFeilmelding(enumTilNavn(kalenderNavn))); //TODO?
        }
    };

    return (
        <Modal
            size='sm'
            aria-labelledby='contained-modal-title-vcenter'
            centered
            show={show}
        >
            <Modal.Header closeButton>
                <Modal.Title id='contained-modal-title-vcenter'>
                    Hva heter du?
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <InputGroup className='mb-3'>
                    <Form.Control
                        onChange={(e) => setKalenderNavn(navnTilEnum(e.target.value))}
                        placeholder='Navn'
                        aria-label='Navn'
                        aria-describedby='basic-addon1'
                    />
                </InputGroup>
                <p className='red'>{feilmelding}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleLogin} variant='success'>
                    Send Inn
                </Button>
            </Modal.Footer>
        </Modal>
    )
}