// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { firebaseApiKey } from "./secrets";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: firebaseApiKey,
    authDomain: "julekalender-efc91.firebaseapp.com",
    projectId: "julekalender-efc91",
    storageBucket: "julekalender-efc91.appspot.com",
    messagingSenderId: "570361722323",
    appId: "1:570361722323:web:d3d967b007c0034530ab26"
  };
// Initialize Firebase

const app = initializeApp(firebaseConfig);
// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);